import { Component, isDevMode } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';
import { TranslationComponent } from '../../translation/translation.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  token: string;
  error: boolean;
  textoModal: string;
  usuario: string;
  contrasena: string;
  datosLoginValidation: any;
  loadingSpinnerLogin = false;
  botonAccederVisibleLogin = true;
  messageLogin: boolean;
  messageRecuperarPass: boolean;
  respuesta: any;
  algunaVezLogueado: any;
  CambioPassword: any;
  IsAdministrador: any;
  UserAccess: any;
  TextoInicioSesion: any;
  idiomas: boolean;

  constructor(
    private _loginService: LoginService,
    private _funcionesService: FuncionesService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.error = false;
    this.token = this._loginService.token;

  //Comprobar que solo se aplica cuando la solución se está ejecutando en local.
  //Esa condicionalidad no está funcionamndo, realmente se puede hacer algo así?
    if (isDevMode) {
      // this.usuario = 'ANA1';
      // this.contrasena = 'ANA1';
    }
  }

  validaLogin(f: NgForm) {
    // Validamos que solo acceda cuando el formulario es valido, es decir que todos los campos pasen rellenados
    if (f.valid) {
        this.usuario=this.usuario.toUpperCase();
      this._loginService.getToken(this.usuario, this.contrasena).subscribe(
        data => {
          this.token = data.Token;
          this._loginService.getValidation(this.usuario, this.contrasena, this.token).subscribe(
            data => {
              this.datosLoginValidation = this._funcionesService.decodificarToken(data);
              if (this.datosLoginValidation.Valido === true) {
                /**
                 * Guardamos TipoCliente en localStorage
                 * Cuando TipoCliente = 1, es monopunto
                 * Cuando TipoCliente = 2, es multipunto
                 * Cuando TipoCliente = 3, es AdminFincas
                 */
                localStorage.setItem('TipoCliente', this.datosLoginValidation.TipoCliente);

                /** Hacemos la llamada de DatosTitular y entramos a la OV */
                this._loginService.getLogin(this.usuario, this.contrasena).subscribe(
                  datosLogin => {
                    this.botonAccederVisibleLogin = true;
                    this.loadingSpinnerLogin = false;
                    // Cargo los datos en la variable del servicio para poder compartirlo entre pestañas
                    this._loginService.datosTitular = this._funcionesService.decodificarToken(datosLogin);

                    localStorage.setItem('usuarioCambioPass', this.usuario);
                    localStorage.setItem('passCambioPass',this.contrasena);
                    localStorage.setItem('u', this._funcionesService.codificarDatos(this.usuario));
                    localStorage.setItem('p', this._funcionesService.codificarDatos(this.contrasena));
                    localStorage.setItem('token', this._loginService.token);
                    localStorage.setItem('isAdminFincas', this._loginService.datosTitular.IsAdministrador);

                    if (this._loginService.datosTitular.IsAdministrador === true) {
                      localStorage.setItem('idCliente', JSON.stringify(this._loginService.datosTitular.IdAdministrador));
                    } else {
                      localStorage.setItem('idCliente', JSON.stringify(this._loginService.datosTitular.IdCliente));
                    }
                    localStorage.setItem('CambioPassword', (this._loginService.datosTitular.CambioPassword) ? this._loginService.datosTitular.CambioPassword: false);
                    localStorage.setItem('IsAdministrador', this._loginService.datosTitular.IsAdministrador);

                    // Si CambioPassword es false, obliga a cambiar la contraseña por primera vez

                    this.CambioPassword = localStorage.getItem('CambioPassword');
                    // this.CambioPassword = localStorage.getItem('CambioPassword');
                    this.IsAdministrador = localStorage.getItem('IsAdministrador');

                    if (this.CambioPassword === 'False' && this.IsAdministrador === 'false') {
                      this.router.navigate(['/password']);
                    } else {
                      //Aquí definimos la que será la página de inicio una vez te hayas logueado.

                      // this.router.navigate(['/resumen']);

                      //Han solicitado que ésta cambie, la nueva página inicial "Inicio" está constituida por un grupo de iconos 
                      // que enlazan con secciones existentes de la Oficina Virtual
                      //y otras nuevas cuya creación ha solicitado expresamente Elekluz para este desarrollo
                      //por lo que en cierto sentido este proyecto se aleja del concepto inicial de la oficina virtual
                      //para constituir algo diferente

                      this.router.navigate(['/inicio']);
                    }
                  }
                );
              } else {
                this.botonAccederVisibleLogin = true;
                this.loadingSpinnerLogin = false;
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    texto: 'Usuario o contraseña incorrectos.',
                    titulo: 'Error en el login'
                  }
                });
              }
            },
            err => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  texto: 'Usuario o contraseña incorrectos.',
                  titulo: 'Error en el login'
                }
              });
              this.botonAccederVisibleLogin = true;
              this.loadingSpinnerLogin = false;
            }
          );
        });
      this.botonAccederVisibleLogin = false;
      this.loadingSpinnerLogin = true;

    }
  }

  ngOnInit() {
    // Mostramos mensaje si la variable messageLogin llega a true
      if(environment.messageLogin){
        this.messageLogin = true;
      }
      if(environment.messageRecuperarPass){
        this.messageRecuperarPass = true;
      }
      this.idiomas = environment.idiomas;
      this.UserAccess = environment.UserAccess;
      this.TextoInicioSesion = environment.TextoInicioSesion;
    }
}
