export const environment = {
  production: true,
  messageLogin: false,
  isTcurvas: true,
  user: 'QVVESU5GT1I=',
  password: 'QVVESU5GT1I=',
  // urlApi: 'https://apiovelekluz.portalswitching.com/APIOV/',
  // urlApi: 'https://sigeenergia.com:8385/APIOV/', //PRODUCIÓN
  // urlCch: 'https://sigeenergia.com:8385/APIOVTM/',//PRODUCIÓN
  urlApi: 'https://apiovelekluzdelay.sigeenergia.com/APIOV/',//DESARROLLO
  urlCch: 'https://apiovelekluzdelay.sigeenergia.com/APIOVTM/',//DESARROLLO
  cryptoKey: '9ou82P8GY89aExNP0AR0AH8z4NSvcn6m',
  cryptoIV: 'foDgwQ7Uw3Vr9Q5X',
  companyName: 'ELEK Comercializadora Eléctrica, S.A.',
  companyAddress: 'Edif. Sorolla Center Av. Cortes Valencianas 58 planta 9 Oficina 1, Valencia',
  companyEmail: 'info@elekluz.com',
  companyPhone: '',
  urlAvisoLegal: '',
  urlCookies: '',
  urlUso: '',
  UserAccess: 'DNI/CIF/NIF/EMAIL',
  TextoInicioSesion: 'INICIO DE SESIÓN',
  HabilitarTPV: false,
  iconoresumennuevalectura: 'fab fa-searchengin fa-4x',
  iconoresumendatospago: 'far fa-credit-card-blank fa-4x',
  iconoresumenconsulta: 'far fa-exclamation-square fa-4x',
  alinearmenusuperior: 'izquierda', // derecha izquierda o centro
  MostrarConsumosPorHora: false,
  idiomas: true,
  email: 'clientes@elekluz.com',// email al que llegaran todos los formularios de la oficina virtual | clientes@elekluz.com
  comparativa: true, //Gráfico de resumen comparativa importes facturas
  historico: false, //Gráfico de resumen histórico importes facturas
  DisabledBotonModificarDatos:true,//Botón modificardatos
  DisabledBotonRealizarConsulta:false,//Botón realizar consulta
  DisabledBotonNuevaLectura:true, //Botón realizar nueva lectura
  MostrarTarifaGrupo:true,
  MostrarPerfilFacturacion:true,
  MostrarFacturaExcel:false,
  RegistrarAceptacionDeUso: false,
  VersionCondicionesUso: "V1",
  MostrarPreciosContrato: false,
  MostrarPerfilContratoSoloUnaPotenciaDHA: false,
  TiempoEsperaGraficos: '500',
  agrupacontratoscups: false,
  MostrarDescargaMasivaFacturasPorCups: true,
  internalApiUrl: '',
  mesesgraficocch: 1,
  maxCUPSgraficoCCH: 3,
  cchWitchCups: false,
  CambiaTituloCChGraph: false,
  DialogFacturasPendientePago: false,
  MostrarBotonFacturasImpagadas: true,
  MostrarBotonModificiacionDatosPerfil: true,
  MostrarDeuda: true,
  PrefijoNumeracionFacturas: '',
  PrefijoNumeracionContratos: '',
  GraficoConecta2: false,
  MostrarReactivaComparativaConsumos : true,
  OcultaTipoContacto1: '',
  UltimasFacturasResumen: null,
  DobleGraficoConsumosPorHora: false,
  mostrarFechaVencimientoFactura: true,
  mostrarLimitePagoFactura: false,
  _URL_ : "https://oficinavirtual.elekluz.com",
  messageRecuperarPass: false,
  MostrarAdministradorListadoContratoFechaAlta: false,
  MostrarAdministradorListadoContratoFechaVto: false,
  MostrarAdministradorListadoContratoFechaBaja: false,
  MostrarAdministradorListadoContratoEntorno: false,
  MostrarListadoContratoDireccionSuministro:true,
  MostrarListadoContratoDenominacionCliente: true,
  MostrarConsumosPorHoraClientes: false,
  MostrarPerfilContratoColectivo: false,
  UnirPerfilDatosEnvioYContacto: true,
  MostrarDatosContratoEnPerfil: true,
  marca: '',
  ApiCallContratosListaElekluz: true, // Trae el listado de contratos campos extra, tarifa, titular ...
  MostrarDescargaMasivaFacturasBotonExcel: false,
  MostrarDescargaMasivaFacturasFiltroCif: true, // oculta o muestra el input para filtrar por el importe
  MostrarDescargaMasivaFacturasFiltroImporte: true, // oculta o muestra el input para filtrar por el importe
  MostrarDescargaMasivaFacturasFiltroTarifa: true, // oculta o muestra el input para filtrar por la tarifa
  MostrarResumenGraficosDeMultipuntoAAdministradorFincas: false,
  defaultLang:'es',
  OcultarFacturasConecta2DeAntesDel31x11x19: false,
  MostrarListadoContratoBotonGraficos: true,
  CambiarTitularElekluz:true,
  BotonElekluz: false,
  OcultarContactosEleia: false,
  SimplificacionSerieFactura: false
};
