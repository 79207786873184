import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SelectionModel } from '@angular/cdk/collections';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { LoginService } from '../../../services/login/login.service';
import { ResumenService } from '../../../services/resumen/resumen.service';
import { GraficosService } from './../../../services/config/graficos/graficos.service';
import { Factura } from '../../../interfaces/factura';
import { Contrato } from '../../../interfaces/contrato';
import { SpinnerDialogComponent } from '../../dialogs/spinner-dialog/spinner-dialog.component';
import { LuzygasDialogComponent } from '../../dialogs/luzygas-dialog/luzygas-dialog.component';
import { FormularioPopUpSinPagarComponent } from '../../dialogs/pop-up-sinpagar-dialog/pop-up-sinpagar.component';
import { FormularioPagoRedsysComponent } from '../formularios/formulario-pago-redsys/formulario-pago-redsys.component';
import { ConsultaDialogComponent } from '../../dialogs/consulta-dialog/consulta-dialog.component';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { ModificadatosDialogComponent } from '../../dialogs/modificadatos-dialog/modificadatos-dialog.component';
// import { CoreEnvironment } from '@angular/core';
// import { element } from '@angular/core';
HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

//En esta  pagina debe de permitir descargarse las ultimas facturas generadas.
//¿Cómo podría hacerse y cuánto podría llevar?
//No se ha avanzado en este sentido

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})
export class ResumenComponent implements OnInit {
  TipoCliente: string;
  Highcharts = Highcharts;
  options: any;
  tokenvalidado: any;
  // HIGHCHARTS MONOPUNTO
  // DATOS GRAFICOS
  // TODO: Hacer una interface para los graficos que el series lo cargue a 0 para evitar el series undefined
  graficoUltimasFacturasAnoMonopunto: any;
  graficoConsumosUltimoAnoMonopunto: any;
  graficoImportesFacturasMonopunto: any;
  graficoConsumosMonopunto: any;
  graficoComparativaImportesMonopunto: any;
  graficoComparativaConsumosMonopunto: any;
  graficoComparativaConsumosMultipunto: any;
  graficoComparativaImportesMultipunto: any;
  graficoFacturasMultipunto: any;
  graficoConsumosMultipunto: any;
  graficoEstadosContratosAdminFincas: any;
  graficoNumeroContratos: any;

  // ARRAYS GRAFICOS MONOPUNTO
  arrayResultadoImportesMonopunto: any;
  entornoImportesMonopunto: any = '';

  arrayResultadoCompImportesMonopunto: any;
  arrayResultadoCompConsumosMonopunto: any;
  arrayResultadoCompConsumosMultipunto: any;
  arrayResultadoCompImportesMultipunto: any;

  arrayGraficoHistImportesMonopunto: any = [];

  MostrarTarifaGrupo:boolean;
  arrayGraficoCompImportesAnioActualMonopunto: any = [];
  arrayGraficoCompConsumosActivaAnioActualMonopunto: any = [];
  arrayGraficoCompConsumosReactivaAnioActualMonopunto: any = [];
  arrayGraficoCompImportesAnioAnteriorMonopunto: any = [];
  arrayGraficoCompConsumosActivaAnioAnteriorMonopunto: any = [];
  arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto: any = [];
  arrayGraficoCompFechasImportesMonopunto: any = [];
  arrayGraficoCompFechasConsumosMonopunto: any = [];

  arrayResultadoConsumosMonopunto: any;
  arrayGraficoActivasConsumosMonopunto: any = [];
  arrayGraficoReactivasConsumosMonopunto: any = [];
  arrayFechasConsumosMonopunto: any = [];

  // ARRAYS GRAFICOS MULTIPUNTO
  arrayGraficoHistImportesMultipunto: any = [];
  arrayFechasFacturasMultipunto: any = [];
  arrayImporteLinea1Multipunto: any = [];
  arrayResultadoFacturasMultipunto: any;
  arrayResultadoConsumosMultipunto: any;
  arrayFechasConsumosMultipunto: any = [];
  arrayDesgloseConsumosActivaMultipunto: any = [];
  arrayDesgloseConsumosReactivaMultipunto: any = [];

  MostrarResumenGraficosDeMultipuntoAAdministradorFincas: boolean;

  // ARRAYS GRAFICOS ADMINISTRADOR FINCAS
  arrayResultadoEstadosContratosFincas: any = [];
  arraySituaciones: any = [];
  arrayContadorContratos: any = [];
  array: any = [];

  // VARIABLES
  ultimasFacturas: Factura[] = [];
  ultimosContratos: Contrato[] = [];
  importesFactura: Factura[] = [];
  IdsDocumento = [];

  pendientespago: any = 0;

  facturasImpagadas: boolean;
  MostrarBotonFacturasImpagadas: any;

  fechaHoy: string;
  fechaInicio: string;
  fechaInicioGraficoMultipunto: string;
  fechaInicioComporativaMonopunto: string;
  iconoresumendatospago: string;
  iconoresumennuevalectura: string;
  iconoresumenconsulta: string;
  msgError: string;

  usuarioMonopunto:boolean;
  adminInfo: any;
  numClientes = 0;
  numContratosLuz = 0;
  numContratosGas = 0;
  numDeuda = 0;

  datosMultipunto: any;

  tarifaMonopunto: any;
  tarifaGrupoMonopunto: any;
  perfilFacturacionMonopunto: any;
  direccionMonopunto: any;
  cupsMonopunto: any;
  entornoMonopunto: any;
  dialogRef: any;
  companyName: any;
  UltimasFacturasResumen: any;
  IdCliente:any;
  private _snackBar: MatSnackBar;

  arrayConceptos: any = [];
  arrayImportesMultipunto: any = [];
  arrayImportesMonopunto: any = [];
  CupsMultipunto: any;
  TamanoListadoCupsCliente: any;
  arrayCups: any[] = [];
  cupsFiltered: string[];
  ListadoIdsFacturas: any=[];

  DatosCupsMultipunto: any;
  Entorno: any;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;

  datosCupsMonopunto: any;

  spinnerLoadingUltimasFacturasMonopunto = true;
  textoTablaUltimasFacturasVaciaMonopunto = false;

  spinnerLoadingUltimasFacturasMultipunto = true;
  textoTablaUltimasFacturasVacia = false;

  spinnerUltimasFacturasAdminFincas = true;
  textoUltimasFacturasAdminFincas = false;

  spinnerUltimasContratosAdminFincas = true;
  textoUltimasContratosAdminFincas = false;
  
  mostrarFormularioAlta: boolean;
  luzygas: boolean;
  luz:boolean;
  gas:boolean;
  isLoading: boolean;
  historico:boolean;
  comparativa:boolean;
  MostrarPerfilFacturacion:boolean;
  DisabledBotonFacturaExcel:boolean;
  DisabledBotonRealizarConsulta:boolean;
  DisabledBotonModificarDatos:boolean;
  DisabledBotonNuevaLectura:boolean;
  IsAdministrador:boolean;

  TiempoEsperaGraficos:any;
  DialogFacturasPendientePago:any;
  numContratosSinPagar:any;
  FecInicio: any;
  FecFin:any;
  cif:any;

  internalApiUrl:string = '';
  MostrarDeuda = environment.MostrarDeuda;
  MostrarReactivaComparativaConsumos = environment.MostrarReactivaComparativaConsumos;
  PrefijoNumeracionFacturas = environment.PrefijoNumeracionFacturas;
  GraficoConecta2 = environment.GraficoConecta2;

  HabilitarTPV = environment.HabilitarTPV;
  OcultarContactosEleia = environment.OcultarContactosEleia;
  SimplificacionSerieFactura = environment.SimplificacionSerieFactura;



  selectedCups = new FormControl();

  @ViewChild('sort') sort: MatSort;
  @ViewChild('sort2') sort2: MatSort;
  @ViewChild('sort3') sort3: MatSort;
  @ViewChild('sort4') sort4: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('paginator3') paginator3: MatPaginator;
  @ViewChild('paginator4') paginator4: MatPaginator;


  columnasUltimasFacturasMesMP: string[] = [];


  columnasUltimasFacturasMesMonopunto: string[] = [];


  columnasUltimasFacturas: string[] = [];

  columnasUltimosContratos: string[] = [];

  dataSourceUltimasFacturasMesMonopunto = new MatTableDataSource<Factura>();
  dataSourceUltimasFacturas = new MatTableDataSource<Factura>();
  dataSourceUltimasFacturasAux = new MatTableDataSource<Factura>();
  dataSourceUltimosContratos = new MatTableDataSource<Contrato>();
  selection = new SelectionModel<Factura>(true, []);
  arraySeleccionados: any;


  MostrarAdministradorListadoContratoEntorno: Boolean;

  constructor(
    private router: Router,
    private _facturasService: FacturasService,
    public dialog: MatDialog,
    private _funcionesService: FuncionesService,
    private _loginService: LoginService,
    private _contratosService: ContratosService,
    private _resumenService: ResumenService,
    private _GraficosService: GraficosService
  ) {
    this.TipoCliente = localStorage.getItem('TipoCliente');
    this.fechaHoy = moment().format('YYYY-MM-DD HH:mm:ss').toString(); // Hoy
    this.fechaInicio = moment().subtract('1', 'month').format('YYYY-MM-DD HH:mm:ss').toString();
    this.fechaInicioGraficoMultipunto = moment().subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss').toString();
    this.fechaInicioComporativaMonopunto = moment().subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss').toString();
    this.iconoresumendatospago = environment.iconoresumendatospago;
    this.iconoresumennuevalectura = environment.iconoresumennuevalectura;
    this.iconoresumenconsulta = environment.iconoresumenconsulta;
    this.MostrarPerfilFacturacion=environment.MostrarPerfilFacturacion;
    this.MostrarTarifaGrupo=environment.MostrarTarifaGrupo;
    this.DisabledBotonModificarDatos=environment.DisabledBotonModificarDatos;
    this.DisabledBotonRealizarConsulta=environment.DisabledBotonRealizarConsulta;
    this.DisabledBotonNuevaLectura=environment.DisabledBotonNuevaLectura;
    this.TiempoEsperaGraficos=environment.TiempoEsperaGraficos;
    this.DialogFacturasPendientePago=environment.DialogFacturasPendientePago;
    this.companyName=environment.companyName;
    this.UltimasFacturasResumen=environment.UltimasFacturasResumen;
    this.internalApiUrl=environment.internalApiUrl;
    // this.internalApiUrl=''; // TODO: CAMBIAR POR LA LINEA ANTERIOR

    this.mostrarFormularioAlta = true;
    this.MostrarResumenGraficosDeMultipuntoAAdministradorFincas = environment.MostrarResumenGraficosDeMultipuntoAAdministradorFincas;
  }
  isNumber(value) {
    return !Number.isNaN(Number(value));
  }
  ngOnInit() {

    
    (this.MostrarAdministradorListadoContratoEntorno ) ? this.columnasUltimasFacturas.push('Entorno') : null;
    this.columnasUltimasFacturas.push('Cliente');
    this.columnasUltimasFacturas.push('FechaFactura');
    this.columnasUltimasFacturas.push('NumFactura');
    this.columnasUltimasFacturas.push('Importe');
    this.columnasUltimasFacturas.push('Descargar');

    (this.MostrarAdministradorListadoContratoEntorno ) ? this.columnasUltimosContratos.push('Entorno') : null;
    this.columnasUltimosContratos.push('Cliente');
    this.columnasUltimosContratos.push('FechaAlta');
    this.columnasUltimosContratos.push('CodigoContrato');
    this.columnasUltimosContratos.push('CodigoCUPS');


    this.MostrarAdministradorListadoContratoEntorno = environment.MostrarAdministradorListadoContratoEntorno;

    // Relleno las columnas de la tabla UltimasFacturasMesMP
    (this.MostrarAdministradorListadoContratoEntorno ) ? this.columnasUltimasFacturasMesMP.push('Entorno') : null;
    this.columnasUltimasFacturasMesMP.push('Factura');
    this.columnasUltimasFacturasMesMP.push('FechaFactura');
    this.columnasUltimasFacturasMesMP.push('ImporteTotal');
    if(this.MostrarDeuda){
      this.columnasUltimasFacturasMesMP.push('Estado');
    }
    this.columnasUltimasFacturasMesMP.push('Descargar');
    (this.HabilitarTPV) ? this.columnasUltimasFacturasMesMP.push('Pagar') : null; // TPV


    // Relleno las columnas de la tabla columnasUltimasFacturasMesMonopunto
    (this.MostrarAdministradorListadoContratoEntorno ) ? this.columnasUltimasFacturasMesMonopunto.push('Entorno') : null;
    this.columnasUltimasFacturasMesMonopunto.push('Factura');
    this.columnasUltimasFacturasMesMonopunto.push('FechaFactura');
    this.columnasUltimasFacturasMesMonopunto.push('ImporteTotal');
    if(this.MostrarDeuda){
      this.columnasUltimasFacturasMesMonopunto.push('Estado');
    }
    this.columnasUltimasFacturasMesMonopunto.push('Descargar');
    (this.HabilitarTPV) ? this.columnasUltimasFacturasMesMonopunto.push('Pagar') : null; // TPV

    this.facturasImpagadas = false;
    this.MostrarBotonFacturasImpagadas = environment.MostrarBotonFacturasImpagadas;

    this.historico = environment.historico;
    this.comparativa = environment.comparativa;

    // MONOPUNTO
    if (this.TipoCliente === '1') {
      // Últimas Facturas
      // this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
      //   data => {
          
      //     this.ListadoIdsFacturas = this._funcionesService.decodificarToken(data);
      //     // console.log(this.ListadoIdsFacturas);
      //     this.IdsDocumento = this.ListadoIdsFacturas.IdsDocumento;
      //     this.tokenvalidado = this._funcionesService.decodificarToken(data);
      //     if(this.tokenvalidado.Valido == true){
      //       this._facturasService.getListadoFacturas(localStorage.getItem('idCliente'), this.fechaInicio, this.fechaHoy,true,'','',this.UltimasFacturasResumen).subscribe(
      //         async data => {
      //           this.ultimasFacturas = this._funcionesService.decodificarToken(data);
      //           if (this.ultimasFacturas.length === 0) {
      //             this.spinnerLoadingUltimasFacturasMonopunto = false;
      //             this.textoTablaUltimasFacturasVaciaMonopunto = true;

      //           } else {
      //             this.spinnerLoadingUltimasFacturasMonopunto = false;
      //             this.textoTablaUltimasFacturasVaciaMonopunto = false;
      //             this.loadUltimasFacturasData();
      //           }
      //         },
      //         err => {
      //           this.spinnerLoadingUltimasFacturasMonopunto = false;
      //           this.textoTablaUltimasFacturasVaciaMonopunto = true;
      //         }
      //       )}else{
      //         this.router.navigate(['/salir']);
      //       }});

      // Gráficos MONOPUNTO
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._loginService.getLogin(
        this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('u'))),
        this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('p')))
      ).subscribe(async data => {
        this._loginService.datosTitular = this._funcionesService.decodificarToken(data);
        console.log(this._loginService.datosTitular);
        this.tarifaMonopunto = this._loginService.datosTitular.CUPS[0].Tarifa;
        this.tarifaGrupoMonopunto = this._loginService.datosTitular.CUPS[0].TarifaGrupo;
        if(!this.MostrarPerfilFacturacion){  }else{
          this.perfilFacturacionMonopunto = this._loginService.datosTitular.CUPS[0].PerfilFacturacion;
        }
           this.direccionMonopunto =
            this._loginService.datosTitular.CUPS[0].TipoVia +
            ' ' +
            this._loginService.datosTitular.CUPS[0].Calle +
            ' ' +
            this._loginService.datosTitular.CUPS[0].Numero +
            ' ' +
            this._loginService.datosTitular.CUPS[0].Aclarador +
            ', ' +
            this._loginService.datosTitular.CUPS[0].Ciudad;

        this.cupsMonopunto = this._loginService.datosTitular.CUPS[0].CodigoCups;
        let first = true;
        setInterval(() => {
          if ((localStorage.getItem('idioma') !== localStorage.getItem('actualCupsFilterLanguage') &&  localStorage.getItem('idioma') !== null) || first){
            first = false;
            localStorage.setItem('actualCupsFilterLanguage', localStorage.getItem('idioma'));
          this.TamanoListadoCupsCliente = this._loginService.datosTitular.CUPS.length;

          for (let i = 0; i < this.TamanoListadoCupsCliente; i++) {
            this.arrayCups = this.arrayCups.concat([{ IdCups: this._loginService.datosTitular.CUPS[i].IdCups, CodigoCups: this._loginService.datosTitular.CUPS[i].CodigoCups }]);
          }
          this.cupsFiltered = this.arrayCups;
          localStorage.setItem('cupsFiltered', JSON.stringify(this.cupsFiltered));
          /**/

          this.entornoMonopunto = this._loginService.datosTitular.CUPS[0].Entorno;

          // Gráfico - Importes Factura
          this._contratosService.getDatosCups(this._loginService.datosTitular.CUPS[0].IdCups).subscribe(
            async datosCupsMonopunto => {
              this.datosCupsMonopunto = this._funcionesService.decodificarToken(datosCupsMonopunto);
            }
          );
          setTimeout(() => {this._facturasService.getImportes(this._loginService.datosTitular.CUPS[0].IdCups, this.fechaInicioComporativaMonopunto, this.fechaHoy).subscribe(
            async dataImportes => {
              let arrayFechasImportesMonopunto = [], arrayGraficoImportesMonopunto = [];
              let arrayDesgloseConceptoDerechosMonopunto: any = [];
              let arrayDesgloseConceptoAlquilerMonopunto: any = [];
              let arrayDesgloseConceptoDescuentosCargosMonopunto: any = [];
              let arrayDesgloseConceptoEnergiaMonopunto: any = [];
              let arrayDesgloseConceptoExcesoMonopunto: any = [];
              let arrayDesgloseConceptoGasMonopunto: any = [];
              let arrayDesgloseConceptoImpuestoElectricoMonopunto: any = [];
              let arrayDesgloseConceptoPotenciaMonopunto: any = [];
              let arrayDesgloseConceptoProductosMonopunto: any = [];
              let arrayDesgloseConceptoReactivaMonopunto: any = [];
              let arrayDesgloseConceptoVariosMonopunto: any = [];
              let arrayDesgloseConceptoImporteImpuesto: any = [];



              this.arrayImportesMonopunto = this._funcionesService.decodificarToken(dataImportes);

            
              this.arrayImportesMonopunto.forEach(e => {
                arrayGraficoImportesMonopunto = arrayGraficoImportesMonopunto.concat(e.Importe);
                arrayFechasImportesMonopunto = arrayFechasImportesMonopunto.concat(moment(e.FechaFactura).locale('es').format('MMM YY'));
                arrayDesgloseConceptoAlquilerMonopunto = arrayDesgloseConceptoAlquilerMonopunto.concat(e.ImporteAlquiler);
                arrayDesgloseConceptoDerechosMonopunto = arrayDesgloseConceptoDerechosMonopunto.concat(e.ImporteDerechos);
                arrayDesgloseConceptoDescuentosCargosMonopunto = arrayDesgloseConceptoDescuentosCargosMonopunto.concat(e.ImporteDescuentosCargos);
                arrayDesgloseConceptoEnergiaMonopunto = arrayDesgloseConceptoEnergiaMonopunto.concat(e.ImporteEnergia);
                arrayDesgloseConceptoExcesoMonopunto = arrayDesgloseConceptoExcesoMonopunto.concat(e.ImporteExceso);
                arrayDesgloseConceptoGasMonopunto = arrayDesgloseConceptoGasMonopunto.concat(e.ImporteGas);
                arrayDesgloseConceptoImpuestoElectricoMonopunto = arrayDesgloseConceptoImpuestoElectricoMonopunto.concat(e.ImporteImpuestoElectrico);
                arrayDesgloseConceptoPotenciaMonopunto = arrayDesgloseConceptoPotenciaMonopunto.concat(e.ImportePotencia);
                arrayDesgloseConceptoProductosMonopunto = arrayDesgloseConceptoProductosMonopunto.concat(e.ImporteProductos);
                arrayDesgloseConceptoReactivaMonopunto = arrayDesgloseConceptoReactivaMonopunto.concat(e.ImporteReactiva);
                arrayDesgloseConceptoVariosMonopunto = arrayDesgloseConceptoVariosMonopunto.concat(e.ImporteVarios);
                arrayDesgloseConceptoImporteImpuesto = arrayDesgloseConceptoImporteImpuesto.concat(e.ImporteImpuesto);
              });
              this.entornoImportesMonopunto = this._loginService.datosTitular.CUPS[0].Entorno;
              let series = [];
              if (this.datosCupsMonopunto.EntornoTexto === 'Luz') {
                series = [
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Alquiler'), data: arrayDesgloseConceptoAlquilerMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Derechos'), data: arrayDesgloseConceptoDerechosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Descuentos Cargos'), data: arrayDesgloseConceptoDescuentosCargosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Energia'), data: arrayDesgloseConceptoEnergiaMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Excesos'), data: arrayDesgloseConceptoExcesoMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Impuesto Electrico'), data: arrayDesgloseConceptoImpuestoElectricoMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Potencia'), data: arrayDesgloseConceptoPotenciaMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Productos'), data: arrayDesgloseConceptoProductosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva'), data: arrayDesgloseConceptoReactivaMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Varios'), data: arrayDesgloseConceptoVariosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Impuesto'), data: arrayDesgloseConceptoImporteImpuesto }
                ];
              } else {
                series = [
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Alquiler'), data: arrayDesgloseConceptoAlquilerMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Derechos'), data: arrayDesgloseConceptoDerechosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Descuentos Cargos'), data: arrayDesgloseConceptoDescuentosCargosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Energia'), data: arrayDesgloseConceptoEnergiaMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Gas'), data: arrayDesgloseConceptoGasMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Productos'), data: arrayDesgloseConceptoProductosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Varios'), data: arrayDesgloseConceptoVariosMonopunto },
                  { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Impuesto'), data: arrayDesgloseConceptoImporteImpuesto }
                ];
              }

              this.options = JSON.parse(this._GraficosService.graficosOption);
              this.graficoImportesFacturasMonopunto = {
                lang: this.options,
                chart: { type: 'column' },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                      ]
                    }
                  }

                },
                title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Detalle Facturas') },
                subtitle: {
                  text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Se muestran las facturas detalladas.')
                },
                xAxis: { categories: arrayFechasImportesMonopunto },
                yAxis: {
                  allowDecimals: false,
                  min: 0,
                  title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Desglose Conceptos €') }
                },
                tooltip: {
                  pointFormat:
                    '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                  shared: true
                },
                plotOptions: {
                  column: {
                    stacking: 'sum'
                  },
                  series: {
                    pointWidth: 30,
                    cursor: 'pointer'
                  }
                },
                series: series,

                credits: { enabled: false }
              };
            },
            err => {
              this.options = JSON.parse(this._GraficosService.graficosOption);
              this.graficoImportesFacturasMonopunto = {
                lang: this.options,
                chart: { type: 'column' },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                      ]
                    }
                  }

                },
                title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Detalle Facturas') },
                subtitle: {
                  text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Se muestran las facturas detalladas.')
                },
                xAxis: { categories: [] },
                yAxis: {
                  allowDecimals: false,
                  min: 0,
                  title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Desglose Conceptos €') }
                },
                tooltip: {
                  pointFormat:
                    '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                  shared: true
                },
                plotOptions: {
                  column: {
                    stacking: 'sum'
                  },
                  series: {
                    pointWidth: 30
                  }
                },
                series: [],
                credits: { enabled: false }
              };
            }
          );
          }, this.TiempoEsperaGraficos);

          if(this.numContratosGas>0 && this.numContratosLuz==0){
            this.gas=true;
          }

          if(this.numContratosLuz>0 && this.numContratosGas==0){
            this.luz=true;
          }

          // Gráfico - COMPARATIVA FACTURAS ULTIMO AÑO MONOPUNTO */
          setTimeout(() => {
            if(!this.comparativa){}else{

                this._facturasService.getImportesBianual(this._loginService.datosTitular.CUPS[0].IdCups,this.fechaInicioComporativaMonopunto,this.fechaHoy).subscribe(
                async datosCompImportes => {
                  this.arrayResultadoCompImportesMonopunto = this._funcionesService.decodificarToken(datosCompImportes);
                  this.arrayGraficoCompFechasImportesMonopunto = [];
                  this.arrayGraficoCompImportesAnioActualMonopunto = [];
                  this.arrayGraficoCompImportesAnioAnteriorMonopunto = [];
                  
                  this.options = JSON.parse(this._GraficosService.graficosOption);

                  for (let i = 1; i < Object.keys(this.arrayResultadoCompImportesMonopunto.Valores[0]).length; i++) {
                    (this.arrayResultadoCompImportesMonopunto.Valores[0] != undefined) ? this.arrayGraficoCompImportesAnioActualMonopunto.push(this.arrayResultadoCompImportesMonopunto.Valores[0]["m"+i]) : null;
                    (this.arrayResultadoCompImportesMonopunto.Valores[1] != undefined) ? this.arrayGraficoCompImportesAnioAnteriorMonopunto.push(this.arrayResultadoCompImportesMonopunto.Valores[1]["m"+i]) : null;
                  }

                this.graficoComparativaImportesMonopunto = {
                  lang: this.options,
                  chart: { type: 'area' },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [
                          'printChart',
                          'separator',
                          'downloadPNG',
                          'downloadJPEG',
                          'downloadPDF',
                          'downloadSVG',
                          'separator',
                          'downloadCSV',
                          'downloadXLS'
                        ]
                      }
                    }

                  },
                  title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa Importes Facturas') },
                  subtitle: {
                    text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa importes de las facturas año actual y año anterior.')
                  },
                  xAxis: [{
                    categories: this.options.months
                  }
                ],
                  yAxis: { title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Importe en €') } },
                  series: [
                  {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior'),
                      states: { hover: { color: '#BADA55' } },
                      data: this.arrayGraficoCompImportesAnioActualMonopunto,
                      color: "#89ccd3"
                    },
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual'),
                      states: { hover: { color: '#BADA55' } },
                      data: this.arrayGraficoCompImportesAnioAnteriorMonopunto,
                      color: "#89d38e"
                    }
                  ],
                  plotOptions: {
                    series: {
                      cursor: 'pointer',
                 }
                  },
                  credits: { enabled: false }
                };

              });

            }

        }, this.TiempoEsperaGraficos);
        
        // Gráfico - HISTÓRICO FACTURAS MONOPUNTO */
        setTimeout(() => {
          if(!this.historico){}else{
              this._facturasService.getImportes(
                this._loginService.datosTitular.CUPS[0].IdCups,
                this.fechaInicioComporativaMonopunto,
                this.fechaHoy
              ).subscribe(
                async datosCompImportes => {
                this.arrayResultadoCompImportesMonopunto = this._funcionesService.decodificarToken(datosCompImportes);
                this.arrayGraficoCompFechasImportesMonopunto = [];
                // console.log(this.arrayResultadoCompImportesMonopunto);
                for (const i of this.arrayResultadoCompImportesMonopunto) {
                  this.arrayGraficoCompFechasImportesMonopunto = this.arrayGraficoCompFechasImportesMonopunto.concat(
                    moment(i.FechaFactura).locale('es').format('MMM YY')
                  );
                  this.arrayGraficoHistImportesMonopunto=this.arrayGraficoHistImportesMonopunto.concat(i.Importe);
                }

                this.options = JSON.parse(this._GraficosService.graficosOption);
                this.graficoComparativaImportesMonopunto = {
                  lang: this.options,
                  chart: { type: 'line' },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [
                          'printChart',
                          'separator',
                          'downloadPNG',
                          'downloadJPEG',
                          'downloadPDF',
                          'downloadSVG',
                          'separator',
                          'downloadCSV',
                          'downloadXLS'
                        ]
                      }
                    }

                  },
                  title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Histórico Importes Facturas') },
                  subtitle: {
                    text:
                      this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Histórico de los importes de las facturas.')
                  },
                  xAxis: {
                    // tslint:disable-next-line:max-line-length
                    categories: this.arrayGraficoCompFechasImportesMonopunto,
                    crosshair: true
                  },
                  yAxis: { title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Importe en €') } },
                  series: [
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Importes'),
                      states: { hover: { color: '#BADA55' } },
                      data: this.arrayGraficoHistImportesMonopunto
                    }
                  ],
                  plotOptions: {
                    series: {
                      cursor: 'pointer',
                  }
                  },
                  credits: { enabled: false }
                };
              });
          }
        }, this.TiempoEsperaGraficos);

          // Gráfico - CONSUMOS MONOPUNTO
        setTimeout(() => {
          this._facturasService.getConsumos(this._loginService.datosTitular.CUPS[0].IdCups, this.fechaInicioComporativaMonopunto, this.fechaHoy).subscribe(
            async datosConsumos => {
              let arrayDesgloseActivaP1Monopunto: any = [];
              let arrayDesgloseActivaP2Monopunto: any = [];
              let arrayDesgloseActivaP3Monopunto: any = [];
              let arrayDesgloseActivaP4Monopunto: any = [];
              let arrayDesgloseActivaP5Monopunto: any = [];
              let arrayDesgloseActivaP6Monopunto: any = [];
              let arrayDesgloseReactivaP1Monopunto: any = [];
              let arrayDesgloseReactivaP2Monopunto: any = [];
              let arrayDesgloseReactivaP3Monopunto: any = [];
              let arrayDesgloseReactivaP4Monopunto: any = [];
              let arrayDesgloseReactivaP5Monopunto: any = [];
              let arrayDesgloseReactivaP6Monopunto: any = [];
              this.arrayResultadoConsumosMonopunto = this._funcionesService.decodificarToken(datosConsumos);

              for (let i = 0; i < this.arrayResultadoConsumosMonopunto.length; i++) {
                this.arrayFechasConsumosMonopunto = this.arrayFechasConsumosMonopunto.concat(
                  moment(this.arrayResultadoConsumosMonopunto[i].FechaFactura).locale('es').format('MMM YY')
                );
                arrayDesgloseActivaP1Monopunto = arrayDesgloseActivaP1Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ActivaP1);
                arrayDesgloseActivaP2Monopunto = arrayDesgloseActivaP2Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ActivaP2);
                arrayDesgloseActivaP3Monopunto = arrayDesgloseActivaP3Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ActivaP3);
                arrayDesgloseActivaP4Monopunto = arrayDesgloseActivaP4Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ActivaP4);
                arrayDesgloseActivaP5Monopunto = arrayDesgloseActivaP5Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ActivaP5);
                arrayDesgloseActivaP6Monopunto = arrayDesgloseActivaP6Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ActivaP6);
                arrayDesgloseReactivaP1Monopunto = arrayDesgloseReactivaP1Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ReactivaP1);
                arrayDesgloseReactivaP2Monopunto = arrayDesgloseReactivaP2Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ReactivaP2);
                arrayDesgloseReactivaP3Monopunto = arrayDesgloseReactivaP3Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ReactivaP3);
                arrayDesgloseReactivaP4Monopunto = arrayDesgloseReactivaP4Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ReactivaP4);
                arrayDesgloseReactivaP5Monopunto = arrayDesgloseReactivaP5Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ReactivaP5);
                arrayDesgloseReactivaP6Monopunto = arrayDesgloseReactivaP6Monopunto.concat(this.arrayResultadoConsumosMonopunto[i].ReactivaP6);
              }

              const potenciasActivas = [];
              const potenciasReactivas = [];
              const numeroPActivaYReactiva = [];

              if (arrayDesgloseActivaP1Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasActivas.push(arrayDesgloseActivaP1Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseActivaP2Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasActivas.push(arrayDesgloseActivaP2Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseActivaP3Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasActivas.push(arrayDesgloseActivaP3Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseActivaP4Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasActivas.push(arrayDesgloseActivaP4Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseActivaP5Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasActivas.push(arrayDesgloseActivaP5Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseActivaP6Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasActivas.push(arrayDesgloseActivaP6Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }

              if (arrayDesgloseReactivaP1Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasReactivas.push(arrayDesgloseReactivaP1Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseReactivaP2Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasReactivas.push(arrayDesgloseReactivaP2Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseReactivaP3Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasReactivas.push(arrayDesgloseReactivaP3Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseReactivaP4Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasReactivas.push(arrayDesgloseReactivaP4Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseReactivaP5Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasReactivas.push(arrayDesgloseReactivaP5Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
              if (arrayDesgloseReactivaP6Monopunto.reduce((total, amount) => total + amount) > 0) { potenciasReactivas.push(arrayDesgloseReactivaP6Monopunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }

              let arrayObjActivaYReactiva = new Array();
              let tota = 0;

              for (let i = 0; i < potenciasActivas.length; i++) {

                if (potenciasActivas[i]) {
                  arrayObjActivaYReactiva[i] = new Object();
                  if (numeroPActivaYReactiva[i] === 0) {
                    arrayObjActivaYReactiva[i].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Activa P') + (i + 2);
                  } else {
                    arrayObjActivaYReactiva[i].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Activa P') + (i + 1);
                  }
                  arrayObjActivaYReactiva[i].data  = potenciasActivas[i];
                  arrayObjActivaYReactiva[i].stack = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Activa');
                }

                tota = potenciasActivas.length + i;
                // console.log(tota);

                if (potenciasReactivas[i]) {
                  arrayObjActivaYReactiva[tota] = new Object();
                  if (numeroPActivaYReactiva[tota] === 0) {
                    arrayObjActivaYReactiva[tota].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva P') + (i + 2);
                  } else {
                    arrayObjActivaYReactiva[tota].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva P') + (i + 1);
                  }
                  arrayObjActivaYReactiva[tota].data  = potenciasReactivas[i];
                  arrayObjActivaYReactiva[tota].stack = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva');
                }

              }
              if(this.GraficoConecta2){
                arrayObjActivaYReactiva = [
                  { name: 'Activa P1(kWh)', data: arrayDesgloseActivaP1Monopunto },
                  { name: 'Activa P2(kWh)', data: arrayDesgloseActivaP2Monopunto},
                  { name: 'Activa P3(kWh)', data: arrayDesgloseActivaP3Monopunto},
                  { name: 'Activa P4(kWh)', data: arrayDesgloseActivaP4Monopunto},
                  { name: 'Activa P5(kWh)', data: arrayDesgloseActivaP5Monopunto},
                  { name: 'Activa P6(kWh)', data: arrayDesgloseActivaP6Monopunto}
                ];
              }

              // /Fill Array

              this.options = JSON.parse(this._GraficosService.graficosOption);
              this.graficoConsumosMonopunto = {
                lang: this.options,
                chart: { type: 'column' },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                      ]
                    }
                  }

                },
                title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Consumos Facturas') },
                subtitle: {
                  text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Desglose de consumos de facturas por mes')
                },
                xAxis: {
                  categories: this.arrayFechasConsumosMonopunto,
                  crosshair: true
                },
                yAxis: { title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Desglose Consumos')  } },
                series: arrayObjActivaYReactiva,
                tooltip: {
                  pointFormat:
                    '<span  style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                  shared: true
                },
                plotOptions: {
                  column: {
                    stacking: 'normal'
                }
              },
                credits: { enabled: false }
              };
            }
          );
        }, this.TiempoEsperaGraficos);


          // Gráfico - COMPARATIVA CONSUMOS ULTIMO AÑO MONOPUNTO
        setTimeout(() => {
          this._facturasService.getConsumosBianual(this._loginService.datosTitular.CUPS[0].IdCups, this.fechaInicioComporativaMonopunto, this.fechaHoy).subscribe(
            async datosCompConsumos => {

              this.arrayResultadoCompConsumosMonopunto = this._funcionesService.decodificarToken(datosCompConsumos);
              this.options = JSON.parse(this._GraficosService.graficosOption);

              // console.log("COMPARATIVA CONSUMOS ULTIMO AÑO MONOPUNTO");
              // console.log(this.arrayResultadoCompConsumosMonopunto);

              this.arrayGraficoCompConsumosActivaAnioActualMonopunto = [];
              this.arrayGraficoCompConsumosReactivaAnioActualMonopunto = [];
              this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto = [];
              this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto = [];
              this.options = JSON.parse(this._GraficosService.graficosOption);

              console.log("this.arrayResultadoCompImportesMonopunto");
              console.log(this.arrayResultadoCompImportesMonopunto);
              console.log("this.arrayResultadoCompConsumosMonopunto");
              console.log(this.arrayResultadoCompConsumosMonopunto);

              if(this.arrayResultadoCompConsumosMonopunto != undefined && this.arrayResultadoCompConsumosMonopunto.length > 0){
                for (let i = 0; i < this.arrayResultadoCompConsumosMonopunto.length; i++) {
                  this.arrayGraficoCompFechasConsumosMonopunto = this.arrayGraficoCompFechasConsumosMonopunto.concat(
                    moment(this.arrayResultadoCompConsumosMonopunto[i].FechaFactura).locale('es').format('MMM YY')
                  );

                  if (moment().year() === moment(this.arrayResultadoCompConsumosMonopunto[i].FechaFactura).year()) {
                    this.arrayGraficoCompConsumosActivaAnioActualMonopunto = this.arrayGraficoCompConsumosActivaAnioActualMonopunto.concat(
                      this.arrayResultadoCompConsumosMonopunto[i].ConsumoActivaTotal
                    );
                    this.arrayGraficoCompConsumosReactivaAnioActualMonopunto = this.arrayGraficoCompConsumosReactivaAnioActualMonopunto.concat(
                      this.arrayResultadoCompConsumosMonopunto[i].ConsumoReactivaTotal
                    ).concat('kVAr');
                    this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto = this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto.concat("");
                    this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto = this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto.concat("").concat('kVAr');
                  } else {
                    this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto = this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto.concat(
                      this.arrayResultadoCompConsumosMonopunto[i].ConsumoActivaTotal
                    );
                    this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto = this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto.concat(
                      this.arrayResultadoCompConsumosMonopunto[i]
                        .ConsumoReactivaTotal
                    ).concat('kVAr');
                    this.arrayGraficoCompConsumosActivaAnioActualMonopunto = this.arrayGraficoCompConsumosActivaAnioActualMonopunto.concat("");
                    this.arrayGraficoCompConsumosReactivaAnioActualMonopunto = this.arrayGraficoCompConsumosReactivaAnioActualMonopunto.concat("").concat('kVAr');
                  }
                }
              }


              for (let i = 1; i < Object.keys(this.arrayResultadoCompConsumosMonopunto.Valores[0]).length; i++) {
                  (this.arrayResultadoCompConsumosMonopunto.Valores[0] != undefined) 
                  ? this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto.push(this.arrayResultadoCompConsumosMonopunto.Valores[0]["m"+i]) : null;
                  (this.arrayResultadoCompConsumosMonopunto.Valores[1] != undefined) 
                  ? this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto.push(this.arrayResultadoCompConsumosMonopunto.Valores[1]["m"+i]) : null;
                  (this.arrayResultadoCompConsumosMonopunto.Valores[2] != undefined) 
                  ? this.arrayGraficoCompConsumosActivaAnioActualMonopunto.push(this.arrayResultadoCompConsumosMonopunto.Valores[2]["m"+i]) : null;
                  (this.arrayResultadoCompConsumosMonopunto.Valores[3] != undefined) 
                  ? this.arrayGraficoCompConsumosReactivaAnioActualMonopunto.push(this.arrayResultadoCompConsumosMonopunto.Valores[3]["m"+i]) : null;

              }

              if(this.companyName==='Grupo Gasindur S.L.'){
                this.graficoComparativaConsumosMonopunto = {
                  lang: this.options,
                  chart: { type: 'line' },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [
                          'printChart',
                          'separator',
                          'downloadPNG',
                          'downloadJPEG',
                          'downloadPDF',
                          'downloadSVG',
                          'separator',
                          'downloadCSV',
                          'downloadXLS'
                        ]
                      }
                    }

                  },
                  title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa ') },
                  subtitle: {
                    text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa consumos de las facturas año actual y año anterior.')
                  },
                  xAxis: {
                    categories:
                      this.arrayGraficoCompFechasConsumosMonopunto
                    ,
                    crosshair: true
                  },
                  yAxis: { title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Consumo en kWh') } },
                  series: [
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior Activa'),
                      states: { hover: { color: '#ff9696' } },
                      data: this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto,
                      color: "#ff9696"
                    },
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual Activa'),
                      states: { hover: { color: '#dab8ed' } },
                      data: this.arrayGraficoCompConsumosActivaAnioActualMonopunto,
                      color: "#dab8ed"
                    },
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior Reactiva'),
                      states: { hover: { color: '#89ccd3' } },
                      data: this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto,
                      color: "#89ccd3"
                    },
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual Reactiva'),
                      states: { hover: { color: '#89d38e' } },
                      data: this.arrayGraficoCompConsumosReactivaAnioActualMonopunto,
                      color: "#89d38e"
                    }
                  ],
                  plotOptions: {
                    series: {
                      cursor: 'pointer',
                      events: {
                        click: function (e) {
                          console.log('Descargar factura');
                        }
                      },
                      pointWidth: 30
                    }
                  },
                  credits: { enabled: false }
                };


              }else{


                this.graficoComparativaConsumosMonopunto = {
                  lang: this.options,
                  chart: { type: 'area' },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [
                          'printChart',
                          'separator',
                          'downloadPNG',
                          'downloadJPEG',
                          'downloadPDF',
                          'downloadSVG',
                          'separator',
                          'downloadCSV',
                          'downloadXLS'
                        ]
                      }
                    }

                  },
              title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa Consumos Facturas') },
            subtitle: {
              text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa consumos de las facturas año actual y año anterior.')
                  },
                  xAxis: [{
                    categories: this.options.months
                  }
                ],
                  yAxis: { title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Consumo en kWh') } },
                  series: [
                  {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior Activa'),
                      states: { hover: { color: '#ff9696' } },
                      data: this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto,
                      color: "#ff9696"
                    },
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual Activa'),
                      states: { hover: { color: '#dab8ed' } },
                      data: this.arrayGraficoCompConsumosActivaAnioActualMonopunto,
                      color: "#dab8ed"
                    },
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior Reactiva'),
                      states: { hover: { color: '#89ccd3' } },
                      data: this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto,
                      color: "#89ccd3"
                    },
                    {
                      name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual Reactiva'),
                      states: { hover: { color: '#89d38e' } },
                      data: this.arrayGraficoCompConsumosReactivaAnioActualMonopunto,
                      color: "#89d38e"
                    }
                  ],
                  plotOptions: {
                    series: {
                      cursor: 'pointer',
                      events: {
                        click: function (e) {
                          console.log('Descargar factura');
                        }
                      }
                    }
                  },
                  credits: { enabled: false }
                };
              }
            }

          );
        }, this.TiempoEsperaGraficos);
        }
      }, 2000);
        }
      )}else{
        this.router.navigate(['/salir']);
      }});
    }


    // MULTIPUNTO
    if (this.TipoCliente === '2' || this.TipoCliente === '3') {
      // Contadores
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          //console.log('3');
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._resumenService.getAdminInfo(localStorage.getItem('idCliente'), false).subscribe(
        async data => {
          this.adminInfo = this._funcionesService.decodificarToken(data);
          this.loadAdminData();
        }
      )}else{
        this.router.navigate(['/salir']);
      }});

      // Facturas último mes
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          //console.log('4');
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
            this._facturasService.getListadoFacturas(localStorage.getItem('idCliente'), this.fechaInicio, this.fechaHoy, true,'','',this.UltimasFacturasResumen).subscribe(
              async data => {
                this.ultimasFacturas = this._funcionesService.decodificarToken(data);
                if (this.ultimasFacturas.length === 0) {
                  this.spinnerLoadingUltimasFacturasMultipunto = false;
                  this.textoTablaUltimasFacturasVacia = true;
                } else {
                  // this.loadUltimasFacturasData();
                }
              },
              err => {
                this.spinnerLoadingUltimasFacturasMultipunto = false;
                this.textoTablaUltimasFacturasVacia = true;
              }
      )}else{
        this.router.navigate(['/salir']);
      }});

      // Gráfico - MULTIPUNTO FACTURAS
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._loginService.getLogin(
        this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('u'))),
        this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('p')))
      ).subscribe(
        async datosLoginMultipunto => {
          this._loginService.datosTitular = this._funcionesService.decodificarToken(datosLoginMultipunto);

          if(this._loginService.datosTitular.CUPS != null){
            this.CupsMultipunto = this._loginService.datosTitular.CUPS[0].IdCups;

            this.TamanoListadoCupsCliente = this._loginService.datosTitular.CUPS.length;
            for (let i = 0; i < this.TamanoListadoCupsCliente; i++) {
              this.arrayCups = this.arrayCups.concat([{ IdCups: this._loginService.datosTitular.CUPS[i].IdCups, CodigoCups: this._loginService.datosTitular.CUPS[i].CodigoCups, Direccion: this._loginService.datosTitular.CUPS[i].TipoVia + ' ' + this._loginService.datosTitular.CUPS[i].Calle + ' ' + this._loginService.datosTitular.CUPS[i].Aclarador }]);
            }
            this.cupsFiltered = this.arrayCups;
            localStorage.setItem('cupsFiltered', JSON.stringify(this.cupsFiltered));
            this.BusquedaNuevoCups(this._loginService.datosTitular.CUPS[0].IdCups);
          }
          setInterval(() => {
            if (localStorage.getItem('idioma') !== localStorage.getItem('actualCupsFilterLanguage') &&  localStorage.getItem('idioma') !== null && localStorage.getItem('actualCupsFilter')){
              this.BusquedaNuevoCups(localStorage.getItem('actualCupsFilter'));
            }
          }, 3000);
        })}else{
          this.router.navigate(['/salir']);
        }});
    }
    // ADMINISTRADOR FINCAS
    if (this.TipoCliente === '3') {
      // Cargar Contadores
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          //console.log('5');
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._resumenService.getAdminInfo(localStorage.getItem('idCliente'), true).subscribe(
        async data => {
          this.adminInfo = this._funcionesService.decodificarToken(data);
          this.loadAdminData();
        })}else{
          this.router.navigate(['/salir']);
        }});

      // Últimas Facturas (Última semana)
      // TODO: Cambiar por .getListadoFacturas con filtros
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          //console.log('6');
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._facturasService.getUltimasFacturas(localStorage.getItem('idCliente'), this.fechaInicio, this.fechaHoy).subscribe(
        async data => {
          this.ultimasFacturas = this._funcionesService.decodificarToken(data);

          if (this.ultimasFacturas.length === 0) {

            this.spinnerUltimasFacturasAdminFincas = false;
            this.textoUltimasFacturasAdminFincas = true;
          } else {

            // cargamos en un array temporal el array filtrando las facturas que no tienen NumFactura (facturas cerradas y con pdf creado)
            const temp: Factura[] = [];
            this.ultimasFacturas.forEach(element => { if (element.NumFactura !== '') { temp.push(element); } });
            this.ultimasFacturas = temp;
            //Probando 13042021
            this.loadUltimasFacturasData();
          }
        },
        err => {
          this.spinnerUltimasFacturasAdminFincas = false;
          this.textoUltimasFacturasAdminFincas = true;
        }
      )}else{
        this.router.navigate(['/salir']);
      }});

      // Últimos Contratos - Admin Fincas
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          //console.log('7');
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._contratosService.getUltimosContratos(localStorage.getItem('idCliente'), this.fechaInicio, this.fechaHoy).subscribe(
        async data => {
          this.ultimosContratos = this._funcionesService.decodificarToken(data);
          if (this.ultimosContratos.length === 0) {
            this.spinnerUltimasContratosAdminFincas = false;
            this.textoUltimasContratosAdminFincas = true;
          } else {
            this.loadUltimosContratosData();
          }
        },
        err => {
          this.spinnerUltimasContratosAdminFincas = false;
          this.textoUltimasContratosAdminFincas = true;
        }
      )}else{
        this.router.navigate(['/salir']);
      }});

      // Gráfico - Estados contratos
      this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
        data => {
          //console.log('8');
          this.tokenvalidado = this._funcionesService.decodificarToken(data);
          if(this.tokenvalidado.Valido == true){
      this._contratosService.getEstados(localStorage.getItem('idCliente')).subscribe(
        async data => {
          this.arrayResultadoEstadosContratosFincas = this._funcionesService.decodificarToken(data);
          for (const i of this.arrayResultadoEstadosContratosFincas) {
            this.arraySituaciones = this.arraySituaciones.concat(i.Situacion);
            this.arrayContadorContratos = this.arrayContadorContratos.concat(i.NumContratos);
          }
          for (let i = 0; i < this.arraySituaciones.length; i++) {
            this.array = this.array.concat([
              {
                name: this.arraySituaciones[i],
                y: this.arrayContadorContratos[i],
                selected: false
              }
            ]);
          }

          this.options = JSON.parse(this._GraficosService.graficosOption);
          this.graficoEstadosContratosAdminFincas = {
            lang: this.options,
            chart: { type: 'line' },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    'printChart',
                    'separator',
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadSVG',
                    'separator',
                    'downloadCSV',
                    'downloadXLS'
                  ]
                }
              }

            },
            title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Estados de los contratos')  },
            subtitle: {
              text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Se muestran la cantidad de contratos que existen en cada estado.')
            },
            tooltip: {
              pointFormat:
                '<span style="color:{series.color}">Contratos: </span>: <b>{point.y}</b><br/>',
              shared: true
            },
            series: [
              {
                type: 'pie',
                allowPointSelect: true,
                keys: ['name', 'y', 'selected', 'sliced'],
                data: this.array,
                showInLegend: true
              }
            ],
            plotOptions: {
              series: {
                cursor: 'pointer',
                events: {
                  click: function(e) {
                    console.log('Descargar factura');
                  }
                }
              }
            },
            credits: { enabled: false }
          };
        })}else{
          this.router.navigate(['/salir']);
        }});
    }

  }
  ValidacionGraficaConsumo(consumos) {
    let aux = false;
    for (let j = 0; j < consumos.length; j++) {
      if ( Number (consumos[j]) > 0) {
        aux = true;
      }
    }
    return aux;
  }

  BusquedaNuevoCups(idCups) {
    localStorage.setItem('actualCupsFilter', idCups);
    localStorage.setItem('actualCupsFilterLanguage', localStorage.getItem('idioma'));
    this._contratosService.getDatosCups(idCups).subscribe(
      async datosCupsMultipunto => {
        this.DatosCupsMultipunto = this._funcionesService.decodificarToken(datosCupsMultipunto);
        this.Distribuidora = this.DatosCupsMultipunto.Distribuidora;
        this.Entorno = this.DatosCupsMultipunto.EntornoTexto;
        this.Tarifa = this.DatosCupsMultipunto.Tarifa;
        this.TarifaGrupo = this.DatosCupsMultipunto.TarifaGrupo;
        this.PerfilFacturacion = this.DatosCupsMultipunto.PerfilFacturacion;
        this.CodigoPostal = this.DatosCupsMultipunto.CodPostal;
        this.Provincia = this.DatosCupsMultipunto.Provincia;
        this.Ciudad = this.DatosCupsMultipunto.Ciudad;
        this.Direccion = this.DatosCupsMultipunto.Calle + ' ' + this.DatosCupsMultipunto.Puerta + ' ' +
        this.DatosCupsMultipunto.Piso + ' ' + this.DatosCupsMultipunto.Aclarador;
        this.Nombre = this.DatosCupsMultipunto.Nombre + ' ' + this.DatosCupsMultipunto.Apellido1 + ' ' + this.DatosCupsMultipunto.Apellido2;
        this.RazonSocial = this.DatosCupsMultipunto.RazonSocial;
      }
    );

    // Gráfico - MULTIPUNTO IMPORTES CONCEPTOS
    setTimeout(() => { this._facturasService.getImportes(idCups, this.fechaInicioGraficoMultipunto, this.fechaHoy).subscribe(
      async datosFacturasMultipunto => {

        let arrayDesgloseConceptoImpuestosMultipunto: any = [];
        let arrayDesgloseConceptoDerechosMultipunto: any = [];
        let arrayDesgloseConceptoAlquilerMultipunto: any = [];
        let arrayDesgloseConceptoDescuentosCargosMultipunto: any = [];
        let arrayDesgloseConceptoEnergiaMultipunto: any = [];
        let arrayDesgloseConceptoExcesoMultipunto: any = [];
        let arrayDesgloseConceptoGasMultipunto: any = [];
        let arrayDesgloseConceptoImpuestoElectricoMultipunto: any = [];
        let arrayDesgloseConceptoPotenciaMultipunto: any = [];
        let arrayDesgloseConceptoProductosMultipunto: any = [];
        let arrayDesgloseConceptoReactivaMultipunto: any = [];
        let arrayDesgloseConceptoVariosMultipunto: any = [];
        this.arrayFechasFacturasMultipunto = [];

        this.arrayResultadoFacturasMultipunto = this._funcionesService.decodificarToken(datosFacturasMultipunto);

        for (const i of this.arrayResultadoFacturasMultipunto) {
          if (localStorage.getItem('idioma') !== null) {
            let idiomaLocale = localStorage.getItem('idioma');
            if (idiomaLocale === 'cat') {
              idiomaLocale = 'ca';
            }
            this.arrayFechasFacturasMultipunto = this.arrayFechasFacturasMultipunto.concat(
              moment(i.FechaFactura).locale(idiomaLocale).format('MMM YY')
            );
          } else {
            this.arrayFechasFacturasMultipunto = this.arrayFechasFacturasMultipunto.concat(
              moment(i.FechaFactura).locale('es').format('MMM YY')
            );
          }

          this.arrayImportesMultipunto = this.arrayImportesMultipunto.concat(i.Importe);

          arrayDesgloseConceptoAlquilerMultipunto = arrayDesgloseConceptoAlquilerMultipunto.concat(i.ImporteAlquiler);
          arrayDesgloseConceptoDerechosMultipunto = arrayDesgloseConceptoDerechosMultipunto.concat(i.ImporteDerechos);
          arrayDesgloseConceptoDescuentosCargosMultipunto = arrayDesgloseConceptoDescuentosCargosMultipunto.concat(i.ImporteDescuentosCargos);
          arrayDesgloseConceptoEnergiaMultipunto = arrayDesgloseConceptoEnergiaMultipunto.concat(i.ImporteEnergia);
          arrayDesgloseConceptoExcesoMultipunto = arrayDesgloseConceptoExcesoMultipunto.concat(i.ImporteExceso);
          arrayDesgloseConceptoGasMultipunto = arrayDesgloseConceptoGasMultipunto.concat(i.ImporteGas);
          
          arrayDesgloseConceptoImpuestoElectricoMultipunto = arrayDesgloseConceptoImpuestoElectricoMultipunto.concat(i.ImporteImpuestoElectrico);
          arrayDesgloseConceptoPotenciaMultipunto = arrayDesgloseConceptoPotenciaMultipunto.concat(i.ImportePotencia);
          arrayDesgloseConceptoProductosMultipunto = arrayDesgloseConceptoProductosMultipunto.concat(i.ImporteProductos);
          arrayDesgloseConceptoReactivaMultipunto = arrayDesgloseConceptoReactivaMultipunto.concat(i.ImporteReactiva);
          arrayDesgloseConceptoVariosMultipunto = arrayDesgloseConceptoVariosMultipunto.concat(i.ImporteVarios);
          arrayDesgloseConceptoImpuestosMultipunto = arrayDesgloseConceptoImpuestosMultipunto.concat(i.ImporteImpuesto);

        }

        let series = [];
        if (this.DatosCupsMultipunto.EntornoTexto == 'Luz') {
          series = [
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Alquiler'), data: arrayDesgloseConceptoAlquilerMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Derechos'), data: arrayDesgloseConceptoDerechosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Descuentos Cargos'), data: arrayDesgloseConceptoDescuentosCargosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Energia'), data: arrayDesgloseConceptoEnergiaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Excesos'), data: arrayDesgloseConceptoExcesoMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Impuesto Electrico'), data: arrayDesgloseConceptoImpuestoElectricoMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Potencia'), data: arrayDesgloseConceptoPotenciaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Productos'), data: arrayDesgloseConceptoProductosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva'), data: arrayDesgloseConceptoReactivaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Varios'), data: arrayDesgloseConceptoVariosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Impuesto'), data: arrayDesgloseConceptoImpuestosMultipunto }
          ];

        } else {
          series = [
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Alquiler'), data: arrayDesgloseConceptoAlquilerMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Derechos'), data: arrayDesgloseConceptoDerechosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Descuentos Cargos'), data: arrayDesgloseConceptoDescuentosCargosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Energia'), data: arrayDesgloseConceptoEnergiaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Gas'), data: arrayDesgloseConceptoGasMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Productos'), data: arrayDesgloseConceptoProductosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Varios'), data: arrayDesgloseConceptoVariosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Impuesto'), data: arrayDesgloseConceptoImpuestosMultipunto }
          ];
        }
        this.options = JSON.parse(
          this._GraficosService.graficosOption
        );
        this.graficoFacturasMultipunto = {
          lang: this.options,
          chart: { type: 'column' },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  'printChart',
                  'separator',
                  'downloadPNG',
                  'downloadJPEG',
                  'downloadPDF',
                  'downloadSVG',
                  'separator',
                  'downloadCSV',
                  'downloadXLS'
                ]
              }
            }

          },
          title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Detalle Facturas') },
          subtitle: {
            text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Se muestran las facturas detalladas.')
          },
          xAxis: { categories: this.arrayFechasFacturasMultipunto },
          yAxis: {
            allowDecimals: false,
            min: 0,
            title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Desglose Conceptos €') }
          },
          tooltip: {
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            shared: true
          },
          plotOptions: {
            column: {
              stacking: 'sum'
            },
            series: {
              pointWidth: 30,
              // point: {
              //   events: {
              //     click: function () {
              //       console.log(this); // get the series for the point
              //     }
              //   }
              // }
            }
          },
          series: series,
          credits: { enabled: false }
        };
      }
    ); }, this.TiempoEsperaGraficos);

    // Gráfico - MULTIPUNTO CONSUMOS
    setTimeout(() => { this._facturasService.getConsumos(idCups, this.fechaInicioGraficoMultipunto, this.fechaHoy).
      subscribe(
        async datosConsumosMultipunto => {

          let arrayDesgloseActivaP1Multipunto: any = [];
          let arrayDesgloseActivaP2Multipunto: any = [];
          let arrayDesgloseActivaP3Multipunto: any = [];
          let arrayDesgloseActivaP4Multipunto: any = [];
          let arrayDesgloseActivaP5Multipunto: any = [];
          let arrayDesgloseActivaP6Multipunto: any = [];
          let arrayDesgloseReactivaP1Multipunto: any = [];
          let arrayDesgloseReactivaP2Multipunto: any = [];
          let arrayDesgloseReactivaP3Multipunto: any = [];
          let arrayDesgloseReactivaP4Multipunto: any = [];
          let arrayDesgloseReactivaP5Multipunto: any = [];
          let arrayDesgloseReactivaP6Multipunto: any = [];
          this.arrayFechasConsumosMultipunto = [];

          this.arrayResultadoConsumosMultipunto = this._funcionesService.decodificarToken(datosConsumosMultipunto);
          for (let i = 0; i < this.arrayResultadoConsumosMultipunto.length; i++) {
            if (localStorage.getItem('idioma') !== null) {
              let idiomaLocale = localStorage.getItem('idioma');
              if (idiomaLocale === 'cat') {
                idiomaLocale = 'ca';
              }
              this.arrayFechasConsumosMultipunto = this.arrayFechasConsumosMultipunto.concat(
                  moment(this.arrayResultadoConsumosMultipunto[i].FechaFactura).locale(idiomaLocale).format('MMM YY')
                );
            } else {
              this.arrayFechasConsumosMultipunto = this.arrayFechasConsumosMultipunto.concat(
                moment(this.arrayResultadoConsumosMultipunto[i].FechaFactura).locale('es').format('MMM YY')
              );
            }

            arrayDesgloseActivaP1Multipunto = arrayDesgloseActivaP1Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP1).concat('kW');
            arrayDesgloseActivaP2Multipunto = arrayDesgloseActivaP2Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP2).concat('kW');
            arrayDesgloseActivaP3Multipunto = arrayDesgloseActivaP3Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP3).concat('kW');
            arrayDesgloseActivaP4Multipunto = arrayDesgloseActivaP4Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP4).concat('kW');
            arrayDesgloseActivaP5Multipunto = arrayDesgloseActivaP5Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP5).concat('kW');
            arrayDesgloseActivaP6Multipunto = arrayDesgloseActivaP6Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP6).concat('kW');
            arrayDesgloseReactivaP1Multipunto = arrayDesgloseReactivaP1Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP1).concat('kVArh');
            arrayDesgloseReactivaP2Multipunto = arrayDesgloseReactivaP2Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP2).concat('kVArh');
            arrayDesgloseReactivaP3Multipunto = arrayDesgloseReactivaP3Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP3).concat('kVArh');
            arrayDesgloseReactivaP4Multipunto = arrayDesgloseReactivaP4Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP4).concat('kVArh');
            arrayDesgloseReactivaP5Multipunto = arrayDesgloseReactivaP5Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP5).concat('kVArh');
            arrayDesgloseReactivaP6Multipunto = arrayDesgloseReactivaP6Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP6).concat('kVArh');
          }

          // Fill Array de objetos(arrayObjActivaYReactiva) para mostrar en *grafico Consumos facturas* -> Monopunto. Mostrando datos y las labels solo de los que tengan información

          const potenciasActivas = [];
          const potenciasReactivas = [];
          const numeroPActivaYReactiva = [];
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP1Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP1Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP2Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP2Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP3Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP3Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP4Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP4Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP5Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP5Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP6Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP6Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP1Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP1Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP2Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP2Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP3Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP3Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP4Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP4Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP5Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP5Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP6Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP6Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }

          let arrayObjActivaYReactiva = new Array();
          let tota = 0;
          for (let i = 0; i < potenciasActivas.length; i++) {

            if (potenciasActivas[i]) {
              arrayObjActivaYReactiva[i] = new Object();
              if (numeroPActivaYReactiva[i] === 0) {
                arrayObjActivaYReactiva[i].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Activa P') + (i + 2);
              } else {
                arrayObjActivaYReactiva[i].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Activa P') + (i + 1);
              }
              arrayObjActivaYReactiva[i].data  = potenciasActivas[i];
              arrayObjActivaYReactiva[i].stack = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Activa');
            }

            tota = potenciasActivas.length + i;

            if (potenciasReactivas[i]) {
              arrayObjActivaYReactiva[tota] = new Object();
              if (numeroPActivaYReactiva[tota] === 0) {
                arrayObjActivaYReactiva[tota].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva P') + (i + 2);
              } else {
                arrayObjActivaYReactiva[tota].name = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva P') + (i + 1);
              }
              arrayObjActivaYReactiva[tota].data  = potenciasReactivas[i];
              arrayObjActivaYReactiva[tota].stack = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Reactiva');
            }

          }
          // /Fill Array
          this.options = JSON.parse(this._GraficosService.graficosOption);

          let printConsumos = [];
          for (let i = 0; i < arrayObjActivaYReactiva.length; i++) {
            for (let j = 0; j < arrayObjActivaYReactiva[i]['data'].length; j++) {
              if ( Number (arrayObjActivaYReactiva[i]['data'][j]) >= 0) {
                printConsumos.push(arrayObjActivaYReactiva[i]['data'][j]);
              }
            }
            arrayObjActivaYReactiva[i]['data'] = printConsumos;
            printConsumos = [];
          }
          if ('Gas' === this.DatosCupsMultipunto.EntornoTexto ) {
            for (let i = 0; i < arrayObjActivaYReactiva.length; i++){
              if ( 'Activa P2' === arrayObjActivaYReactiva[i]['name'] ){
                arrayObjActivaYReactiva[i]['name'] = this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Energia');
                break;
              }
            }
          }
          if(this.GraficoConecta2){
            arrayObjActivaYReactiva = [
              { name: 'Activa P1 (kWh)', data: arrayDesgloseActivaP1Multipunto},
              { name: 'Activa P2 (kWh)', data: arrayDesgloseActivaP2Multipunto },
              { name: 'Activa P3 (kWh)', data: arrayDesgloseActivaP3Multipunto},
              { name: 'Activa P4 (kWh)', data: arrayDesgloseActivaP4Multipunto},
              { name: 'Activa P5 (kWh)', data: arrayDesgloseActivaP5Multipunto},
              { name: 'Activa P6 (kWh)', data: arrayDesgloseActivaP6Multipunto}
            ]
          }
          
          this.graficoConsumosMultipunto = {
            lang: this.options,
            chart: { type: 'column' },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    'printChart',
                    'separator',
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadSVG',
                    'separator',
                    'downloadCSV',
                    'downloadXLS'
                  ]
                }
              }

            },
            title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Desglose Consumos') },
            xAxis: { categories: this.arrayFechasConsumosMultipunto },
            yAxis: {
              allowDecimals: false,
              min: 0,
              title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Desglose Consumos') }
            },
            tooltip: {
              pointFormat:
                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
              shared: true
            },
            plotOptions: {
              column: {
                stacking: 'normal'
              }
            },
            series: arrayObjActivaYReactiva ,
            credits: { enabled: false }
          };
        }
      ); }, this.TiempoEsperaGraficos);

    // GRAFICO COMPARATIVA CONSUMOS MULTIPUNTO
    setTimeout(() => { this._facturasService.getConsumosBianual(idCups, this.fechaInicioComporativaMonopunto, this.fechaHoy).subscribe(
      async datosCompConsumosMultipunto => {

        this.arrayGraficoCompFechasConsumosMonopunto = [];
        this.arrayGraficoCompConsumosActivaAnioActualMonopunto = [];
        this.arrayGraficoCompConsumosReactivaAnioActualMonopunto = [];
        this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto = [];
        this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto = [];
        this.arrayResultadoCompConsumosMultipunto = this._funcionesService.decodificarToken(datosCompConsumosMultipunto);

        this.options = JSON.parse(this._GraficosService.graficosOption);
        
        if(this.arrayResultadoCompConsumosMultipunto.Valores[0] != undefined){

            for (let i = 1; i < Object.keys(this.arrayResultadoCompConsumosMultipunto.Valores[0]).length; i++) {
              
              (this.arrayResultadoCompConsumosMultipunto.Valores[0] != undefined) ? this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[0]["m"+i]) : null ;
              (this.arrayResultadoCompConsumosMultipunto.Valores[1] != undefined) ? this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[1]["m"+i]) : null ;
              (this.arrayResultadoCompConsumosMultipunto.Valores[2] != undefined) ? this.arrayGraficoCompConsumosActivaAnioActualMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[2]["m"+i]) : null ;
              (this.arrayResultadoCompConsumosMultipunto.Valores[3] != undefined) ? this.arrayGraficoCompConsumosReactivaAnioActualMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[3]["m"+i]) : null ;

            }
          }
          
          this.graficoComparativaConsumosMultipunto = {
              lang: this.options,
              chart: { type: 'area' },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [
                      'printChart',
                      'separator',
                      'downloadPNG',
                      'downloadJPEG',
                      'downloadPDF',
                      'downloadSVG',
                      'separator',
                      'downloadCSV',
                      'downloadXLS'
                    ]
                  }
                }

              },
              title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa Consumos Facturas') },
            subtitle: {
              text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa consumos de las facturas año actual y año anterior.')
              },
              xAxis: [{
                categories: this.options.months
              }
            ],
              yAxis: { title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Consumo en kWh') } },
              series: [
              {
                  name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior Activa'),
                  states: { hover: { color: '#ff9696' } },
                  data: this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto,
                  color: "#ff9696"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual Activa'),
                  states: { hover: { color: '#dab8ed' } },
                  data: this.arrayGraficoCompConsumosActivaAnioActualMonopunto,
                  color: "#dab8ed"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior Reactiva'),
                  states: { hover: { color: '#89ccd3' } },
                  data: this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto,
                  color: "#89ccd3"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual Reactiva'),
                  states: { hover: { color: '#89d38e' } },
                  data: this.arrayGraficoCompConsumosReactivaAnioActualMonopunto,
                  color: "#89d38e"
                }
              ],
              plotOptions: {
                series: {
                  cursor: 'pointer',
                  events: {
                    click: function (e) {
                      console.log('Descargar factura');
                    }
                  }
                }
              },
              credits: { enabled: false }
            };
      }
    ); }, this.TiempoEsperaGraficos);


    // COMPARATIVA FACTURAS MULTIPUNTO
    setTimeout(() => {

        this._facturasService.getImportesBianual(idCups, this.fechaInicioComporativaMonopunto, this.fechaHoy).subscribe(
        async datosCompImportesMultipunto => {
            this.arrayGraficoHistImportesMonopunto = [];
            this.arrayGraficoCompFechasImportesMonopunto = [];
            this.arrayGraficoCompImportesAnioActualMonopunto = [];
            this.arrayGraficoCompImportesAnioAnteriorMonopunto = [];
            this.arrayResultadoCompImportesMultipunto = [];
            this.arrayResultadoCompImportesMultipunto = this._funcionesService.decodificarToken(datosCompImportesMultipunto);
            this.options = JSON.parse(this._GraficosService.graficosOption);

          if(this.arrayResultadoCompImportesMultipunto.Valores[0] != undefined){
            for (let i = 1; i < Object.keys(this.arrayResultadoCompImportesMultipunto.Valores[0]).length; i++) {
              (this.arrayResultadoCompImportesMultipunto.Valores[0] != undefined) ? this.arrayGraficoCompImportesAnioActualMonopunto.push(this.arrayResultadoCompImportesMultipunto.Valores[0]["m"+i]) : null ;
              (this.arrayResultadoCompImportesMultipunto.Valores[1] != undefined) ? this.arrayGraficoCompImportesAnioAnteriorMonopunto.push(this.arrayResultadoCompImportesMultipunto.Valores[1]["m"+i]) : null ;
            }
          }
            this.graficoComparativaImportesMultipunto = {
              lang: this.options,
              chart: { type: 'area' },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [
                      'printChart',
                      'separator',
                      'downloadPNG',
                      'downloadJPEG',
                      'downloadPDF',
                      'downloadSVG',
                      'separator',
                      'downloadCSV',
                      'downloadXLS'
                    ]
                  }
                }

              },
              title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa Importes Facturas') },
              subtitle: {
                text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Comparativa importes de las facturas año actual y año anterior.')
              },
              xAxis: [{
                categories: this.options.months
              }
            ],
              yAxis: { title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Importe en €') } },
              series: [
              {
                  name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Anterior'),
                  states: { hover: { color: '#BADA55' } },
                  data: this.arrayGraficoCompImportesAnioActualMonopunto,
                  color: "#89ccd3"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Año Actual'),
                  states: { hover: { color: '#BADA55' } },
                  data: this.arrayGraficoCompImportesAnioAnteriorMonopunto,
                  color: "#89d38e"
                }
              ],
              plotOptions: {
                series: {
                  cursor: 'pointer',
                  events: {
                    click: function (e) {
                      console.log('Descargar factura');
                    }
                  }
                }
              },
              credits: { enabled: false }
            };

          });

    }, this.TiempoEsperaGraficos);

  }


  loadAdminData() {
    this.numClientes = this.adminInfo.Clientes;
    this.numDeuda = this.adminInfo.Deuda;
    this.numContratosLuz = this.adminInfo.ContratosLuz;
    this.numContratosGas = this.adminInfo.ContratosGas;

    const arrayNumeroContratos = [
      { name: 'Luz', y: this.adminInfo.ContratosLuz, selected: false },
      { name: 'Gas', y: this.adminInfo.ContratosGas, selected: false }
    ];

    this.options = JSON.parse(this._GraficosService.graficosOption);
    this.graficoNumeroContratos = {
      lang: this.options,
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXLS'
            ]
          }
        }

      },
      title: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Número de contratos.') },
      tooltip: {
        pointFormat:
          '<span  style="color:{series.color}">Contratos </span>: <b>{point.y}</b><br/>',
        shared: true
      },
      series: [
        {
          type: 'pie',
          innerSize: '40%',
          keys: ['name', 'y', 'selected', 'sliced'],
          data: arrayNumeroContratos,
          showInLegend: true
        }
      ],
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: 0,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '140%'],
          size: '300%'
        }
      },
      credits: { enabled: false }
    };
  }
  loadUltimasFacturasData() {
    this.dataSourceUltimasFacturas = new MatTableDataSource<Factura>(this.ultimasFacturas);
    this.dataSourceUltimasFacturas.sort = this.sort;
    this.dataSourceUltimasFacturas.paginator = this.paginator;
    this.pendientespago = 0;
    console.log(this.ultimasFacturas);
    this.ultimasFacturas.forEach(element => {
      //element.NumeroFactura = element.NumFactura;
      if (element.Estado == false) {
        this.pendientespago ++;
      }
      if(this.SimplificacionSerieFactura && isNaN(Number(element.Serie)) ){
        var year: number = +element.FechaFactura.substring(0,4);
        if(year >= 2021){
          element.Serie = element.Serie.replace(/\D/g,'');
        }
      }
    });
    if (this.pendientespago > 0 && this.DialogFacturasPendientePago) {
      setTimeout(() => {
        this.numContratosSinPagar = this.pendientespago;
        this.popupsinpagar();
      }, 500);
    }
  }
  redsysdialog(factura){
    this.dialogRef=this.dialog.open(FormularioPagoRedsysComponent, {
      //posibilidad de pasar path de la API desde aqui
      data:{
        'factura': factura,
        'companyName': this.companyName,
        'token': localStorage.getItem('token'),
        'internalApiUrl': this.internalApiUrl,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }
  popupsinpagar(){

    this.dialogRef=this.dialog.open(FormularioPopUpSinPagarComponent, {
      data:{
        'numContratosSinPagar': this.numContratosSinPagar,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }
  nuevaLectura(){

    this.dialogRef=this.dialog.open(LuzygasDialogComponent, {
      data:{
        'numContratosLuz': this.numContratosLuz,
        'numContratosGas': this.numContratosGas,
        'entorno': this.entornoMonopunto,
        'EmailContacto': this._funcionesService.buscarEmailContactos(this._loginService.datosTitular.Contactos)
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }

  descargarUltimasFacturas(){
    console.log(this.ultimasFacturas);
     this._facturasService.getDescargaFacturasZip(this.ultimasFacturas).subscribe(
      data => {
        this.isLoading = false;
        const zip = new Blob([data], { type: 'application/zip' });
        const zipURL = URL.createObjectURL(zip);
        window.open(zipURL);
        this.msgError = "Descargando archivo comprimido..."
        this._snackBar.open(this.msgError, "Espere", {
          duration: 2000,
        });
      });
 }

  loadUltimosContratosData() {
    this.dataSourceUltimosContratos = new MatTableDataSource<Contrato>(this.ultimosContratos);
    this.dataSourceUltimosContratos.sort = this.sort2;
    this.dataSourceUltimosContratos.paginator = this.paginator2;
  }
  

  descargarSeleccionados() {
    for (let i = 0; i < this.selection.selected.length; i++) {
      this.arraySeleccionados.push({
        IdContratoDocumento: this.selection.selected[i].IdContratoDocumento
      });
    }
    this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
      data => {
        //console.log('9');
        this.tokenvalidado = this._funcionesService.decodificarToken(data);
        if(this.tokenvalidado.Valido == true){
    this._facturasService
      .getDescargaFacturasZip(this.arraySeleccionados)
      .subscribe(data => {
        this.dialog.open(SpinnerDialogComponent, { width: '450px' });
        const zip = new Blob([data], { type: 'application/zip' });
        const zipURL = URL.createObjectURL(zip);
        this.dialog.closeAll();
        window.open(zipURL);
      })}});
  }

  descargaFactura(datos: any) {
    this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
      data => {
        //console.log('10');
        this.tokenvalidado = this._funcionesService.decodificarToken(data);
        if(this.tokenvalidado.Valido == true){
    this._facturasService.getDescargaFactura(datos.IdDocumento).subscribe(
      data => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    )}});
  }

  search(query: string) {
    const result = this.select(query);
    this.cupsFiltered = result;
  }

  select(query: string): string[] {
    const result: string[] = [];
    for (const a of this.arrayCups) {
      if (a.CodigoCups.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  consultar(){

    this.dialogRef=this.dialog.open(ConsultaDialogComponent, {
      data:{
        'numContratosLuz': this.numContratosLuz,
        'numContratosGas': this.numContratosGas,
        'entorno': this.entornoMonopunto,
        'EmailContacto': this._funcionesService.buscarEmailContactos(this._loginService.datosTitular.Contactos)
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }

  modificar(){

    this.dialogRef=this.dialog.open(ModificadatosDialogComponent, {
      data:{
        'EmailContacto': this._funcionesService.buscarEmailContactos(this._loginService.datosTitular.Contactos)
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });
  }


  mostrarSoloFacturasImpagadas() {

    this.facturasImpagadas = !this.facturasImpagadas;

    // console.log(this.facturasImpagadas);

    if (this.facturasImpagadas){
      // guardamos temporalmente los valores antes de mostrar solo los datos filtratos por las facturas impagadas
      this.dataSourceUltimasFacturasAux.data = this.dataSourceUltimasFacturas.data;
      let arrayFiltradoImpagadas: any = [];

      this.dataSourceUltimasFacturas.data.forEach((value, index) => {

        if(!value.Estado){ arrayFiltradoImpagadas.push(value); }

      });

      this.dataSourceUltimasFacturas.data = arrayFiltradoImpagadas;

    } else {

      // recuperamos el listado previo del filtrado de impagadas
      this.dataSourceUltimasFacturas.data = this.dataSourceUltimasFacturasAux.data;

    }

  }

}
